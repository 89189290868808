<template>
  <div class="error">
    <el-row type="flex"
         align="middle"
         :gutter="16">
      <el-col :span="12">
        <img :src="img_404"
             alt="404">
      </el-col>
      <el-col :span="10"
           class="right"
           :offset="1">
        <h1 class="font36">OOPS!</h1>
        <p class="font24">很抱歉，页面它不小心迷路了！</p>
        <div style="color:#909399;font-size: 14px">请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告</div>
        <el-button size="large"
                style="width: 120px;margin-top: 20px;"
                type="primary"
                @click="handleGoMain">返回首页
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import img_404 from '@/assets/images/gif_404.gif'

export default {
  name: 'ErrorPage',
  data() {
    return {
      img_404
    }
  },
  methods: {
    handleGoMain() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss"  scoped>
  .error {
    width: 80vw;
    height: 100vh;
    min-width: 1200px;
    margin: 0 auto;

    > div {
      padding: 50px 0;

    }
  }

  .right {
    text-align: left;
    padding-left: 30px;

    .font36 {
      font-size: 36px;
      margin-bottom: 20px;
    }

    .font24 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
</style>
